import type { RouteRecordRaw, Router } from 'vue-router';
import auth from '@/app/middleware/auth';

const moduleRoute: RouteRecordRaw = {
  path: '/tournament',
  name: 'Tournament',
  component: () => import('@/shared/layouts/TheLayoutDefault.vue'),
  meta: {
    middleware: [auth],
  },
  children: [
    {
      path: ':tournamentId',
      name: 'Tournament.Main',
      meta: {
        title: 'Tournament',
      },
      component: () => import('@/modules/tournament/pages/Main.vue'),
    },

  ],
};

export default (router: Router) => {
  router.addRoute(moduleRoute);
};
